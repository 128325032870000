.App {
  text-align: left;
  background-color: #000000;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #36bffa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}
.Topbar{
  background-color: #36bffa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
}
.Topbar a{
  position: absolute;
  top:15px;
  right:15px;
}

.Heading{
  background-color: black;
  text-align: center;
  font-size: calc(15px + 1vmin);
  color: #ffffff;
}



  .App-link {
    color: #61dafb;
  }

  .White {
    z-index: "100";
    color: #ffffff;
  }
  
.Demo{
  color: #36bffa;
}

.Foxplorer{
  background-color: #000000;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(10px + 1vmin);
  color: #36bffa;

}

.Clear{
  margin-left:15px;
  cursor: pointer;
}

.Back{
  cursor: pointer;
  color:white;
  margin-left:15px;
  position:absolute;
}

select {
  /* styling */
  background-color: black;
  color:#ffffff;
  border: 3px solid #36bffa;
  border-radius: 4px;
  display: block;
  font: 10px;
  width: 200px;
  line-height: 1.5em;
  padding: 0.5em 0.5em 0.5em 0.5em;

  /* reset */
  margin: 5px;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: none;
}

#Filters{
  background-color: #000000;
  font-size: calc(10px + 1vmin);
  color: #ffffff;
  height:100%;
}
#Filters h3{
  margin-left:15px;
}

#Filters h4 a{
  color: #ffffffff;
}

#Filters h3{
  color: #36bffa;
}



.Right{
  float:right;
}

.ZIndex{
  z-index: "10";
}

#image-container img {
  width:100px;
  height:100px;
  cursor:pointer;
  margin:3px;
  border: 4px solid #000000;
}

#image-container img:hover{
  border: 4px solid #36bffa;
}

.Label {
  font-size: calc(10px + 1vmin);
  margin: 10px;
  color: #36bffa;
}

.FilterList {
  list-style-type: none;
}
.FilterList li{
  display:inline-block;
}

#loader{
  visibility: hidden;
}